import { message } from 'ant-design-vue';
var msgKey = 0;
const tools = {
  /*
   * 缓存方法开始 包含如下方法：
   * 设置缓存storeSet
   * ┣ 传入参数一（必填）：缓存名称 name 设置或修改的缓存名称
   * ┗ 传入参数二：缓存数据 data 设置或修改的缓存数据
   * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
   * 获取缓存storeGet
   * ┗ 传入参数（必填）：缓存名称 name 需要获取的缓存名称
   * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
   * 清除缓存storeClear
   * ┗ 传入参数（必填）：缓存名称 name 需要清除的缓存名称
   */
    storeSet(name, data){
      if(typeof data != 'string'){
        try{
          data = JSON.stringify(data);
        } catch(err) {};
        try{
          data = data + '';
        } catch(err){};
      }
      var data = localStorage.setItem(name, data) || '';
    },
    storeGet(name){
      var data = localStorage.getItem(name) || '';
      try {
        data = JSON.parse(data);
      } catch(err){}
      return data || null
    },
    storeClear(name){
      localStorage.removeItem(name)
    },
  /*
   * 缓存方法结束
   * * * * * * * * * * * 
   * 提示框方法开始
   * 消息提示 msg
   * ┣ 传入参数一（必填）：提示文字 txt 
   * ┗ 传入参数二：参数 op 为数字型则为提示时间，默认0.6秒
   *                           为对象时op.time为提示时间，默认0.6秒
   *                           op.key为提示框标识
   *                           op.type为提示框类型 默认info，其余有success、error、warning
   * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
   * 成功提示 msgSuc
   * ┣ 传入参数一（必填）：提示文字 txt 
   * ┗ 传入参数二：提示时间 time，默认0.6秒
   * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
   * 错误提示 msgErr
   * ┣ 传入参数一（必填）：提示文字 txt 
   * ┗ 传入参数二：提示时间 time，默认0.6秒
   * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
   * 警告提示 msgWar
   * ┣ 传入参数一（必填）：提示文字 txt 
   * ┗ 传入参数二：提示时间 time，默认0.6秒
   * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
   * 加载提示 loading
   * ┣ 传入参数一：提示文字 txt 默认为“请稍后...”
   * ┣ 传入参数二：提示时间 time，默认持续提示
   * ┗ 返回结果：对象.stop 停止方法
   *                 .key 提示框标识
   * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
   * 加载结束提示 loadEnd
   * ┣ 传入参数一（必填）：参数 op op.time为提示时间，默认0.6秒
   * ┃                          op.key为提示框标识
   * ┃                          op.type为提示框类型 默认info，其余有success、error、warning
   * ┗ 传入参数二：提示文字 txt 默认为“完成！”
   */
    msg(txt,op){
      if(typeof op == 'number')op={time:op};
      let time = op&&op.time||0.6,
      data = {
        content: txt,
        duration: time,
      };
      if(op.key){
        data.key = op.key;
      }
      return message[(op&&op.type||'info')](data)
    },
    msgSuc(txt, time){
      return this.msg(txt,{type:'success',time:time})
    },
    msgErr(txt, time){
      return this.msg(txt,{type:'error',time:time})
    },
    msgWar(txt, time){
      return this.msg(txt,{type:'warning',time:time})
    },
    loading(txt, time){
      msgKey++;
      var stop = message.loading({
        content: txt || '请稍后...',
        duration: time || 0,
        key:msgKey
      })
      return {
        stop:stop,
        key:msgKey
      }
    },
    loadEnd(op, txt){
      return this.msg(txt||'完成！',{type:'success',...op})
    }
  /*
   * 提示框方法结束
   */
};

export default tools