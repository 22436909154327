 //百度地图ak注册脚本
 export const loadBMap = (ak)=> {
    return new Promise(function(resolve, reject) {
        if (typeof BMap !== 'undefined') {
            resolve(BMap)
            return true
        }
        window.onBMapCallback = function() {
            resolve(BMap)
        }
        let script = document.createElement('script')
        script.type = 'text/javascript'
        script.src =
            'http://api.map.baidu.com/api?v=2.0&ak=' + ak + '&callback=onBMapCallback'
        script.onerror = reject
        document.head.appendChild(script)
    })
}

// 高德地图key注册脚本
export const loadAMap = (key)=> {
    return new Promise((resolve, reject) => {
      if (window.AMap) {
        resolve(window.AMap)
      } else {
      // 动态创建script标签
        var script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = 'https://webapi.amap.com/maps?v=1.4.9&callback=initAMap&key='+key+'&plugin=AMap.DistrictSearch,AMap.Circle,AMap.LngLat,AMap.Polyline,AMap.LngLat,AMap.Text,AMap.Geocoder';
        // plugin后的是需要的插件
        script.onerror = reject
        document.head.appendChild(script)
      }
      window.initAMap = () => {
        resolve(window.AMap)
      }
    })
}

export const selftagCloud=()=>{
  var script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = 'https://webapi.amap.com/maps?v=1.4.9&callback=initAMap&key='+key+'&plugin=AMap.DistrictSearch,AMap.Circle,AMap.LngLat,AMap.Polyline,AMap.LngLat,AMap.Text,AMap.Geocoder';
        // plugin后的是需要的插件
        script.onerror = reject
        document.head.appendChild(script)

}

