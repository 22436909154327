<template>
  <div class="data">
    <dv-flyline-chart-enhanced :config="config" style="width:100%;height:100%;" />
    <div class="data-title">
      <div class="title-left fl"></div>
      <div class="title-center fl bigtitle" style="">天意集数据监控大屏</div>
      <div class="title-right fr"></div>
    </div>
    <div class="data-content">
      <div class="con-left fl">
        <div class="left-top">
          <div class="info">
            <div class="info-title">各渠道用户数据实时统计</div>
            <img :src="require('@/static/img/bj-1.png')" alt="" class="bj-1">
            <img :src="require('@/static/img/bj-2.png')" alt="" class="bj-2">
            <img :src="require('@/static/img/bj-3.png')" alt="" class="bj-3">
            <img :src="require('@/static/img/bj-4.png')" alt="" class="bj-4">
            <div class="info-main">
              <div class="student-data">
                <div class="data-item">
                  <div class="item">
                    <div class="ico-left">
                      <img src="@/static/student-ico.svg" alt="" />
                      <div class="text">APP端</div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="info">
                      <div class="text">注册用户总数</div>
                      <Autonum class="numb unit" :number="servicesData && servicesData.totalAssets || 0"></Autonum>
                    </div>
                  </div>
                  <div class="item">
                    <div class="info">
                      <div class="text">今日活跃数</div>
                      <Autonum class="numb unit" :number="servicesData && servicesData.totalAssets || 0"></Autonum>
                    </div>
                  </div>
                </div>
                <div class="data-item">
                  <div class="item">
                    <div class="ico-left">
                      <img src="@/static/student-ico.svg" alt="" />
                      <div class="text">微信端</div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="info">
                      <div class="text">微信小程序会员数</div>
                      <Autonum class="numb unit" :number="servicesData && servicesData.totalAssets || 0"></Autonum>
                    </div>
                  </div>
                  <div class="item">
                    <div class="info">
                      <div class="text">视频号粉丝数</div>
                      <Autonum class="numb unit" :number="servicesData && servicesData.totalAssets || 0"></Autonum>
                    </div>
                  </div>
                </div>
                <div class="data-item">
                  <div class="item">
                    <div class="ico-left">
                      <img src="@/static/student-ico.svg" alt="" />
                      <div class="text">抖音端</div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="info">
                      <div class="text">抖音小程序会员数</div>
                      <Autonum class="numb unit" :number="servicesData && servicesData.totalAssets || 0"></Autonum>
                    </div>
                  </div>
                  <div class="item">
                    <div class="info">
                      <div class="text">抖音号粉丝数</div>
                      <Autonum class="numb unit" :number="servicesData && servicesData.totalAssets || 0"></Autonum>
                    </div>
                  </div>
                </div>
                <div class="data-item">
                  <div class="item">
                    <div class="ico-left">
                      <img src="@/static/student-ico.svg" alt="" />
                      <div class="text">美团端</div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="info">
                      <div class="text">美团活跃用户数</div>
                      <Autonum class="numb unit" :number="servicesData && servicesData.totalAssets || 0"></Autonum>
                    </div>
                  </div>
                  <div class="item">
                  </div>
                </div>
                <div class="data-item">
                  <div class="item">
                    <div class="ico-left">
                      <img src="@/static/student-ico.svg" alt="" />
                      <div class="text">线下收银台</div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="info">
                      <div class="text">线下交易用户数</div>
                      <Autonum class="numb unit" :number="servicesData && servicesData.totalAssets || 0"></Autonum>
                    </div>
                  </div>
                  <div class="item">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="top-bottom">
            <div class="title">行业分类</div>
            <img :src="require('@/static/img/bj-1.png')" alt="" class="bj-1">
            <img :src="require('@/static/img/bj-2.png')" alt="" class="bj-2">
            <img :src="require('@/static/img/bj-3.png')" alt="" class="bj-3">
            <img :src="require('@/static/img/bj-4.png')" alt="" class="bj-4">
          </div>
        </div>
        <div class="left-bottom">
          <div class="title">平台技术支持</div>
          <img :src="require('@/static/img/bj-1.png')" alt="" class="bj-1">
          <img :src="require('@/static/img/bj-2.png')" alt="" class="bj-2">
          <img :src="require('@/static/img/bj-3.png')" alt="" class="bj-3">
          <img :src="require('@/static/img/bj-4.png')" alt="" class="bj-4">
          <div class="item" style="height: 200px;width:100%" id="studentPie1"></div>
        </div>
      </div>
      <div class="con-center fl">
        <div class="map-num">
          <p>平台交易实时数据</p>
          <div class="num">
            <span>1</span>
            <span>,</span>
            <span>2</span>
            <span>3</span>
            <span>4</span>
            <span>,</span>
            <span>5</span>
            <span>6</span>
            <span>7</span>
          </div>
          <div style="height: 600px;width: 100%;"></div>
        </div>
        <div class="cen-top" id="mainmap"></div>
        <div class="cen-bottom">
          <div class="title">车辆充电高峰时间</div>
          <img :src="require('@/static/img/bj-1.png')" alt="" class="bj-1">
          <img :src="require('@/static/img/bj-2.png')" alt="" class="bj-2">
          <img :src="require('@/static/img/bj-3.png')" alt="" class="bj-3">
          <img :src="require('@/static/img/bj-4.png')" alt="" class="bj-4">
          <div id="palteMoney" title="平台月交易数据趋势图" style="height: 200px;width: 100%;">
          </div>
        </div>
      </div>
      <div class="con-right fr">
        <div class="right-top">
          <div class="title">合作伙伴数据</div>
          <img :src="require('@/static/img/bj-1.png')" alt="" class="bj-1">
          <img :src="require('@/static/img/bj-2.png')" alt="" class="bj-2">
          <img :src="require('@/static/img/bj-3.png')" alt="" class="bj-3">
          <img :src="require('@/static/img/bj-4.png')" alt="" class="bj-4">
          <div class="wrapper-item">
            <div id="partdata" style="height: 300px;width: 100%"></div>
          </div>
        </div>
        <div class="right-center">
          <div class="title">商品数据</div>
          <img :src="require('@/static/img/bj-1.png')" alt="" class="bj-1">
          <img :src="require('@/static/img/bj-2.png')" alt="" class="bj-2">
          <img :src="require('@/static/img/bj-3.png')" alt="" class="bj-3">
          <img :src="require('@/static/img/bj-4.png')" alt="" class="bj-4">
          <div class="wrapper-item">
            <div id="serviceData" style="    height: 300px;width:100%"></div>
          </div>
        </div>
        <div class="right-bottom">
          <div class="title">商品销量TOP+</div>
          <img :src="require('@/static/img/bj-1.png')" alt="" class="bj-1">
          <img :src="require('@/static/img/bj-2.png')" alt="" class="bj-2">
          <img :src="require('@/static/img/bj-3.png')" alt="" class="bj-3">
          <img :src="require('@/static/img/bj-4.png')" alt="" class="bj-4">
          <div class="wrapper-item">
            <div id="topProduct" style="height: 250px;width: 100%"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/scrollbar/scrollbar.min.css';
import 'swiper/modules/autoplay/autoplay.min.css';
import china from '@/components/530000_full.json';
export default {
  name: 'Home',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      mdn: 0,
      modules: [Pagination, Autoplay],
      data: null,
      allCustomers: 0,
      allProducts: 0,
      areaList: [],
      productList: [],
      servicesData: null,
      pieArr: [ // 饼图
        {
          id: 'studentPie1', obj: null, name: '年龄群体分布', data: [{ name: '服装', value: 200 }, { name: '家具', value: 1200 }, { name: '电子产品', value: 500 }],
        }
      ],
      allstaticinfo: {},
    };
  },
  computed: {
    areaScale() {
      return (val) => parseInt(val / this.allCustomers * 100);
    },
    productScale() {
      return (val) => parseInt(val / this.allProducts * 100);
    },
  },
  created() {
  },
  beforeMount() {
    console.log('挂在前');
  },
  mounted() {
    const that = this;
    this.mdn = 0;
    setTimeout((t) => {
      this.mdn = 1;
      this.getData();
    }, 100);
    // this.drawChart();
    // this.drawSwiper();
    this.echartsPartData()
    this.echartsServiceData()
    this.getsalegoodstop()
    this.tradeData()
    this.mainMap()
  },
  methods: {
    mainMap() {
      var myChart = this.$echarts.init(document.getElementById('mainmap'));
      var icon = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAndJREFUSImdlk1rFEEQhp/qmdmFEIwieFQQRXJRBH+AFw/6c3PxEATxHlEwgkGIJ88BIWZ3Z7s87PRsTU3NZrGg6I/pqbffqu7qEi4zEyKu3VfUtQOpAxAPJO57aa1BCyLBXL/WAkqg0bwF9kY1mBtsqgBag2nHuPStEa/Z9a2oB0ymtSqubxkWw0WT6eNANXJpAioH6McWsBhfm74/aIU1NeMYWZDK9cvYAhag1PXX3o2WlGfoWRWt3bgA5m7cMnS1ZWbJaO0WepcWsK0qc9ZyB0FJeoWwckYtsxLPfn4qhmOwzCEreYnyaPufrEj8pNEvCAsDVGJbbPVMo2thWVY92FLeAEfGCEAic8xC7jPXD8gArHIuDRnG8VvJK+AI5YCWtyiPASFxTs0p8ICVPGemnxmeXHvAhGDC38WKzAHKQyDR8g7lGdB0zF/Q8rpj+gSlYXx9BgyTAbOyXZjlbrduhvIUL5njbu2cLIe7wCzgLgmz/v9KAfRGt7kx6RWby7xEuAgsnLOJ1w2V/mEbP5/Me0Cf3W1eXJP4i/ALyDS8R/jebWBF4oyaT5uNccEmAWSnk8/TGGyzoZaZnrGQewA0nOBzqfCbRr91gC3bdOefq4FLPcOSF1uEa+Z6ivADuDHfrkl8ZaYfEZYOLAJFuMz2GJfMMk5pZV5putNIF7OWIbM20LLBXAe0S3ZYO9cV92UqXXbzxRvWqGW5Vwzt/WkDd/uNWBd6HZUedTA5eKEDsH0eYH9Ke3ue4RSgzfy3lRh+PACtzY8Yw/ZHn6YiwKiAisYjwAJqjfq44gAj4Mny0bvU9qcK4EiiavvWQth/nKqyb5Odpf4/d3ZV6AC8bj8AAAAASUVORK5CYII='
      // 注册 echarts 地图
      console.log(china, '数组')

      // {
      //           name: '上海',
      //           value: [104.22, 23.40, 40]
      //         },
      //         {
      //           name: '昆明',
      //           value: [102.83, 24.88, 110]
      //         },
      //         {
      //           name: '普洱',
      //           value: [100.97, 22.83, 22]    // value的前两项是经纬度坐标，第三项为污染度数据
      //         }
      const zunyiData = [
    {
        "adcode": "520321",
        "people_count_2010": 942904,
        "lat": 24.88,
        "lng": 102.83,
        "name": "昆明",
        "level": "district",
        "parent": "昆明"
    },
 
    {
        "adcode": "520330",
        "people_count_2010": 523180,
        "lat": 26.87,
        "lng": 100.24,
        "name": "丽江古城",
        "level": "district",
        "parent": "丽江古城"
    },{
        "adcode": "520330",
        "people_count_2010": 523180,
        "lat": 27.10,
        "lng": 100.18,
        "name": "玉龙雪山",
        "level": "district",
        "parent": "玉龙雪山"
    }
  ];

      this.$echarts.registerMap('yunnan', china);
      var optionMap = {
        //标题文本配置
        title: {
          text: '',
          textStyle: {
            color: 'white',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: 32,
            lineHeight: 80,
            textBorderColor: 'green',
            textBorderWidth: 1,
            textShadowColor: 'green',
            textShadowBlur: 10,
            textShadowOffsetX: 2,
            textShadowOffsetY: 2
          }
        },

        //提示框组件(可以设置在多种地方)       
        tooltip: {
          show: true, //是否显示提示框组件，包括提示框浮层和 axisPointer。
          trigger: 'item', //触发类型。item,axis,none
          enterable: true,//鼠标是否可进入提示框浮层中，默认为false，
          showContent: true,          //是否显示提示框浮层
          triggerOn: 'mousemove',//提示框触发的条件(mousemove|click|none)  
          showDelay: 0,               //浮层显示的延迟，单位为 ms，默认没有延迟，也不建议设置。在 triggerOn 为 'mousemove' 时有效。
          textStyle: {
            color: 'white',
            fontSize: 12
          },
          padding: [0, 8],
          hideDelay: 10,             //浮层隐藏的延迟
          formatter: (o) => (o.data) ? `<div class="mapico22 "> <div class="circle-cum"></div>
    <div class='circle-cum'></div>

    <div class='circle-cum'></div>

   <div class='circle-cum'></div><div class="info info-cum"><div class="icon"><p><span></span></p></div><div style="margin-left:18px"><div class="name"></div><div class="num">该地客户：</div></div></div></div>` : '',
          backgroundColor: 'green',  //提示框浮层的背景颜色。
          //borderColor: "white",  //图形的描边颜色
          borderWidth: 1,
          alwaysShowContent: true,
          transitionDuration: 1,      //提示框浮层的移动动画过渡时间，单位是 s，设置为 0 的时候会紧跟着鼠标移动。
        },

        //地理坐标系组件。地理坐标系组件用于地图的绘制，支持在地理坐标系上绘制散点图，线集。
        geo: {
          show: true,
          map: 'yunnan',
          roam: false,
          top: 90,
          left: 306,
          zoom: 1,
          aspectScale: 1,
          itemStyle: {
            normal: {
              opacity: .8,              //图形透明度 0 - 1
              borderColor: "yellow",  //图形的描边颜色
              borderWidth: 1,          //描边线宽。为 0 时无描边。
              borderType: 'solid',     //柱条的描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'。
              areaColor: "yellow",   //图形的颜色 #eee
            }
          }
        },

        //系列列表。每个系列通过 type(map, scatter, bar, line, gauge, tree.....) 决定自己的图表类型
        series: [{
          name: '',
          map: 'yunnan',
          type: "scatter",
          coordinateSystem: "geo",
          symbolSize: 0,
          silent: 'none',
          data: zunyiData.map(item => {
            return {
              name: item.name,
              value: [item.lng, item.lat]
            };
          })
        },
        {
          map: 'yunnan',
          type: "map",
          zoom: 1,                //当前视角的缩放比例。
          aspectScale: 1,       //这个参数用于 scale 地图的长宽比。geoBoundingRect.width / geoBoundingRect.height * aspectScale
          roam: false,            //是否开启鼠标缩放和平移漫游。默认不开启
          label: {
            show: false,
            textStyle: {
              color: "green",
              fontSize: 12,
              backgroundColor: ''  //文字背景色
            }
          },
          itemStyle: {
            normal: {
              borderColor: "#00ff00",     //图形的描边颜色
              borderWidth: 2,             //描边线宽。为 0 时无描边。
              borderType: 'solid',        //柱条的描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'。
              areaColor: "rgba(0, 186, 0, 0.8)",   //图形的颜色 #eee
              shadowBlur: 100,            //图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果。
              shadowColor: '#002900',     //阴影色
              shadowOffsetX: 20,          //X轴阴影
              shadowOffsetY: -20,         //Y轴阴影

              label: {
                show: true,
                textStyle: {
                  color: "#ffffc6",//字体颜色
                  fontSize: 12
                }
              }
            },
            //鼠标移入时
            emphasis: {
              borderColor: "#005b89",
              borderWidth: "1",
              areaColor: "yellow",
              label: {
                show: true,
                textStyle: {
                  color: "purple",
                  fontSize: 14
                }
              }
            },

            effect: {
              show: true,
              shadowBlur: 10,
              loop: true
            },
          },
          //自定义图片数组对象[{}, {}...]
        }, ...zunyiData.map((item, index) => {
          return {
            type: "scatter",
            coordinateSystem: "geo",
            //自定义图片的 位置（lng, lat）
            data: [{ name: item.name, value: [item.lng, item.lat] }],
            //自定义图片的 大小
            symbolSize: [20, 20],
            //自定义图片的 路径
            symbol: ``
          }
        })
        ]
      }
      var option = {
        //悬停提示
        //backgroundColor: '#f5f5f5',
        tooltip: {
          trigger: 'item',
          // formatter: '{b}<br/>{c}',
          formatter: function ({ data = { value: [] }, name }, ticket, callback) {
            const value1 = typeof data.value[0] === 'undefined' ? '-' : data.value[0]
            const value2 = typeof data.value[1] === 'undefined' ? '-' : data.value[1]
            const value3 = typeof data.value[2] === 'undefined' ? '-' : data.value[2]
            let html = ''
            html += `<div class="index-map-tooltip-name">${name}</div>`
            html += `<div class="index-map-tooltip-online">数据1：<span class="index-map-tooltip-value">${value1}</span></div>`
            html += `<div class="index-map-tooltip-count">数据2：<span class="index-map-tooltip-value">${value2}</span></div>`
            html += `<div class="index-map-tooltip-user">数据3：<span class="index-map-tooltip-value">${value3}</span></div>`
            return html
          },
          extraCssText: `background: #174699; width: 257px; height: 139px;`
        },
        title: {
          text: '',
          x: "center",
        },
        zoom: 3,
        label: {
          color: 'white',
          fontSize: 10,
          normal: {
            show: true,
            offset: [30, -40],
            color: '#fff',
            formatter: ({ name }) => {
              return `{a|${name}}\n{b|}`
            },
            rich: {
              a: {
                fontSize: 12
              },
              b: {
                width: 28,
                height: 28,
                lineHeight: 12,
                backgroundColor: {
                  image: icon
                }
              }
            }
          },
          emphasis: {
            show: true,
            color: '#fff'
          }
        },
        itemStyle: {
          normal: {
            borderWidth: 2,//边际线大小
            borderColor: '#00ffff',//边界线颜色
            areaColor: '#09295b'//默认区域颜色
          },
          emphasis: {
            show: true,
            areaColor: '#3066ba',//鼠标滑过区域颜色
            label: {
              show: true,
              textStyle: {
                color: '#fff'
              }
            }
          }

        },
        geo: {
          map: '云南地图',
          label: {},
          roam: true,
          itemStyle: {
            normal: {
              opacity: 1,              //图形透明度 0 - 1
              borderColor: "yellow",  //图形的描边颜色
              borderWidth: 2,          //描边线宽。为 0 时无描边。
              borderType: 'solid',     //柱条的描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'。
              areaColor: "blue",   //图形的颜色 #eee
            },
          }
        },
        series: [
          {
            aspectScale: 0.85,
            layoutCenter: ["50%", "50%"], //地图位置
            layoutSize: '138%',
            zoom: 1, //当前视角的缩放比例
            // roam: true, //是否开启平游或缩放
            scaleLimit: { //滚轮缩放的极限控制
              min: 1,
              max: 2
            },
            itemStyle: {
              normal: {
                areaColor: '#0c274b',
                borderColor: '#1cccff',
                borderWidth: 1.5
              },
              emphasis: {
                areaColor: '#02102b',
                label: {
                  color: "#fff"
                }
              }
            },
            tooltip: {
              formatter: function (params, ticket, callback) {
                return (
                  params.seriesName +
                  "<br />" +
                  params.name +
                  "：" +
                  params.value +
                  "吨/㎡"
                );
              }, //数据格式化
            },
            type: 'effectScatter',
            coordinateSystem: 'geo',
            rippleEffect: {
              brushType: 'stroke'
            },
            symbolSize: function (val, params) {
              return 8;
            },
            itemStyle: {
              normal: {
                // areaColor: '#174699',
                areaColor: {
                  image: '', // 支持为 HTMLImageElement, HTMLCanvasElement，不支持路径字符串
                  repeat: 'repeat' // 是否平铺，可以是 'repeat-x', 'repeat-y', 'no-repeat'
                },
                borderColor: '#1ba0f8',
                borderWidth: 1
              },
              emphasis: {
                areaColor: '#1ba0f8'
              }
            },
            type: 'effectScatter',
            coordinateSystem: 'geo',
            data: [
              {
                name: '上海',
                value: [104.22, 23.40, 40]
              },
              {
                name: '昆明',
                value: [102.83, 24.88, 110]
              },
              {
                name: '普洱',
                value: [100.97, 22.83, 22]    // value的前两项是经纬度坐标，第三项为污染度数据
              }
            ]
          }]
      };
      let timer = null, nows = -1;

      myChart.setOption(optionMap);
       //自动切换
       const autoShow = (length) => {
            nows = (nows + 1) % length;
            myChart.dispatchAction({
                type: 'showTip',
                seriesIndex: 0,
                dataIndex: nows
            });
        };
 
        //每隔5秒钟自动切换到下一个数据点
        autoShow(zunyiData.length);
        clearInterval(timer);
        timer = window.setInterval(() => {
            autoShow(zunyiData.length);
        }, 5000);
 
        // 点击事件
        myChart.on('click', function (res) {
            console.log(res);
            if('scatter' === res.componentSubType){
              // alert(`点击了 ${res.name} 图标`);
            }
            if('map' === res.componentSubType){
                //alert(`点击了 ${res.name} 地图`);
            }
          })
    },
    tradeData() {
      const myChartpart = this.$echarts.init(document.getElementById('palteMoney'));
      var option = {
        title: {
          //text: 'Stacked Line'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          textStyle: { //图例文字的样式
            color: '#fff',
            fontSize: 16
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Email',
            type: 'line',
            stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: 'Union Ads',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310]
          },
          {
            name: 'Video Ads',
            type: 'line',
            stack: 'Total',
            data: [150, 232, 201, 154, 190, 330, 410]
          },
          {
            name: 'Direct',
            type: 'line',
            stack: 'Total',
            data: [320, 332, 301, 334, 390, 330, 320]
          },
          {
            name: 'Search Engine',
            type: 'line',
            stack: 'Total',
            data: [820, 932, 901, 934, 1290, 1330, 1320]
          }
        ]
      }
      option && myChartpart.setOption(option);
    },
    /**
     * 商品销量前10
     */
    getsalegoodstop() {
      var x_data = [
        "大理啤酒v8 470ml/瓶",
        "风花雪月 470ml/瓶",
        "椰树椰子汁 245ml/听",
        "雪花啤酒—勇闯天涯 500ml/瓶",
        "王老吉 310ml/听",
        "果粒橙 1.25L/瓶",
        // "可口可乐 330ml/听",
        // "荞化香乔酒 42度白酒荞化香乔酒 45ml/杯",
        // "三江荞酒 40度白酒三江荞酒 50ml/杯",
        // "【云上盛宴】长街宴单人餐+民族服装体验单人餐纳西族长街宴＋单人民族服装体验"
      ];
      const y_data = [23, 17, 9, 7, 7, 6]
      var myChart = this.$echarts.init(document.getElementById('topProduct'));
      var product_echars_option = {
        tooltip: {
          trigger: 'axis'
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: x_data,
            axisLabel: {
              interval: 0,
              margin: 4,
              formatter: function (value) {
                var ret = "";//拼接加\n返回的类目项
                var maxLength = 8;//每项显示文字个数
                var valLength = value.length;//X轴类目项的文字个数
                var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                if (rowN > 1)//如果类目项的文字大于3,
                {
                  for (var i = 0; i < rowN; i++) {
                    var temp = "";//每次截取的字符串
                    var start = i * maxLength;//开始截取的位置
                    var end = start + maxLength;//结束截取的位置
                    //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    temp = value.substring(start, end) + "\n";
                    ret += temp; //凭借最终的字符串
                  }
                  return ret;
                } else {
                  return value;
                }
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
          }
        ],
        series: [
          {
            legend: {
              textStyle: { //图例文字的样式
                color: '#fff',
                fontSize: 16
              },
            },
            name: '销量',
            type: 'bar',
            itemStyle: {
              normal: {
                color: function (params) {
                  // build a color map as your need.
                  var colorList = [
                    '#C1232B', '#B5C334', '#FCCE10', '#E87C25', '#27727B',
                    '#FE8463', '#9BCA63', '#FAD860', '#F3A43B', '#60C0DD',
                    '#D7504B', '#C6E579', '#F4E001', '#F0805A', '#26C0C0'
                  ];
                  return colorList[params.dataIndex]
                }
              }
            },
            data: y_data
          }
        ]
      };
      // 为echarts对象加载数据
      myChart.setOption(product_echars_option);
    },
    echartsPartData() {
      const myChartpart = this.$echarts.init(document.getElementById('partdata'));
      var option;
      option = {
        title: {
          // text: 'Referer of a Website',
          // subtext: 'Fake Data',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          textStyle: { //图例文字的样式
            color: '#fff',
          },
        },
        textStyle: {
          color: 'red'  // 设置文字颜色为红色
        },
        series: [
          {
            legend: {
              textStyle: { //图例文字的样式
                color: '#fff',
              },
            },
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: 'Search Engine' },
              { value: 735, name: 'Direct' },
              { value: 580, name: 'Email' },
              { value: 484, name: 'Union Ads' },
              { value: 300, name: 'Video Ads' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      option && myChartpart.setOption(option);
    },
    echartsServiceData() {
      const myChartpart = this.$echarts.init(document.getElementById('serviceData'));
      var option;
      option = {
        title: {
          // text: 'Referer of a Website',
          // subtext: 'Fake Data',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          textStyle: { //图例文字的样式
            color: '#fff',
          },
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['45%', '60%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              color: '#93faff',
              position: 'center'
            },
            emphasis: {
              label: {
                color: '#93faff',
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: 'Search Engine' },
              { value: 735, name: 'Direct' },
              { value: 580, name: 'Email' },
              { value: 484, name: 'Union Ads' },
              { value: 300, name: 'Video Ads' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      option && myChartpart.setOption(option);
    },
    setMapType(type) {
      this.$store.commit('setStore', { name: 'onMapType', data: type });
    },
    async getData() {
      const res = await this.$utils.api.get({
        url: '/portal/statistics/data',
      });
      this.allstaticinfo = res;
      this.areaList = res.areas;
      this.productList = res.products.reverse();
      this.servicesData = { ...res.statistics };
      this.allCustomers = 0;
      this.allProducts = 0;
      this.areaList.forEach((item) => {
        this.allCustomers += item.customers;
      });
      this.productList.forEach((item) => {
        this.allProducts += item.classQuantity;
      });
      //this.drawAssets(res.assets.reverse());
      //this.drawCompanies(res.companies.reverse());
      this.drawChart();
    },
    drawAssets(arr) {
      const drawAssets = this.$echarts.init(document.getElementById('studentData'));
      const charr = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九', '二十'];
      const date = arr.map((item, idx) =>
        // return charr[idx] + '个月'
        `${charr[idx]}个月`);
      const values = arr.map((item, idx) => item.scale);
      const option = {
        title: { // 标题组件
          text: '单位：原资产%',
          textStyle: {
            color: '#aaaaaa',
            fontWeight: 500,
            fontSize: 14,
            lineHeight: 45,
          },
        },
        legend: { // 图例组件
          name: '资产变化',
          type: 'plain', // 普通图例
          right: 0, // 图例组件离容器右侧的距离。
          itemWidth: 12, // 图例标记的图形宽度。
          itemHeight: 12, // 图例标记的图形高度。
          icon: 'rect', // 图例项的 icon，为 方形
          // data: ["销量"], // 图例的数据数组
          textStyle: {
            color: '#aaaaaa',
            fontWeight: 500,
            fontSize: 12,
            lineHeight: 45,
          },
        },
        xAxis: {
          type: 'category', // x轴设置为类目轴
          axisLine: {
            lineStyle: {
              color: '#aaaaaa', // 文字样式
            },
          },
          axisTick: { // 坐标轴刻度
            show: false,
          },
          axisLabel: {
            margin: 20, // 刻度标签与轴线之间的距离
          },
          data: date,
        },
        yAxis: {
          type: 'value', // y轴设置为数值轴
          axisLine: {
            lineStyle: {
              color: '#aaaaaa', // 文字样式
            },
          },
          axisLabel: {
            margin: 15, // 刻度标签与轴线之间的距离
          },
          splitLine: {
            show: true, // 坐标系分隔线。
            lineStyle: {
              color: 'rgba(255,255,255,0.2)',
            },
          },
        },
        series: [
          {
            name: '资产变化', // 系列名称
            type: 'line', // 类型：折线图
            smooth: true, // 是否平滑曲线显示
            symbol: 'none', // 线两端的标记类型
            lineStyle: { // 线条样式
              width: 3,
            },
            itemStyle: { // 图例的图形样式、线条的样式
              color: {
                type: 'linear', // 渐变
                x: 1,
                y: 0,
                x2: 0,
                y2: 0,
                colorStops: [{
                  offset: 0, color: '#fee78a', // 0% 处的颜色
                }, {
                  offset: 1, color: '#d74277', // 100% 处的颜色
                }],
                global: false, // 缺省为 false
              },
            },
            data: values,
          },
        ],
      };
      drawAssets.setOption(option);
    },
    drawCompanies(arr) {
      const companies = this.$echarts.init(document.getElementById('course'));
      const date = arr.map((item) => item.year);
      const values = [
        {
          name: '', // 系列名称
          type: 'line', // 类型：折线图
          smooth: false, // 是否平滑曲线显示
          symbol: 'none', // 线两端的标记类型
          lineStyle: { // 线条样式
            width: 3,
          },
          itemStyle: { // 图例的图形样式、线条的样式
            color: {
              type: 'linear', // 渐变
              x: 1,
              y: 0,
              x2: 0,
              y2: 0,
              colorStops: [{
                offset: 0, color: '#a790fe', // 0% 处的颜色
              }, {
                offset: 1, color: '#01b7ff', // 100% 处的颜色
              }],
              global: false, // 缺省为 false
            },
          },
          data: [],
        }, {
          name: '', // 系列名称
          type: 'line', // 类型：折线图
          smooth: false, // 是否平滑曲线显示
          symbol: 'none', // 线两端的标记类型
          lineStyle: { // 线条样式
            width: 3,
          },
          itemStyle: { // 图例的图形样式、线条的样式
            color: {
              type: 'linear', // 渐变
              x: 1,
              y: 0,
              x2: 0,
              y2: 0,
              colorStops: [{
                offset: 0, color: '#ffe889', // 0% 处的颜色
              }, {
                offset: 1, color: '#ff9901', // 100% 处的颜色
              }],
              global: false, // 缺省为 false
            },
          },
          data: [],
        }, {
          name: '', // 系列名称
          type: 'line', // 类型：折线图
          smooth: false, // 是否平滑曲线显示
          symbol: 'none', // 线两端的标记类型
          lineStyle: { // 线条样式
            width: 3,
          },
          itemStyle: { // 图例的图形样式、线条的样式
            color: {
              type: 'linear', // 渐变
              x: 1,
              y: 0,
              x2: 0,
              y2: 0,
              colorStops: [{
                offset: 0, color: '#ffaa00', // 0% 处的颜色
              }, {
                offset: 1, color: '#aa0000', // 100% 处的颜色
              }],
              global: false, // 缺省为 false
            },
          },
          data: [],
        }, {
          name: '', // 系列名称
          type: 'line', // 类型：折线图
          smooth: false, // 是否平滑曲线显示
          symbol: 'none', // 线两端的标记类型
          lineStyle: { // 线条样式
            width: 3,
          },
          itemStyle: { // 图例的图形样式、线条的样式
            color: {
              type: 'linear', // 渐变
              x: 1,
              y: 0,
              x2: 0,
              y2: 0,
              colorStops: [{
                offset: 0, color: '#55ff7f', // 0% 处的颜色
              }, {
                offset: 1, color: '#005500', // 100% 处的颜色
              }],
              global: false, // 缺省为 false
            },
          },
          data: [],
        },
      ];
      const typeArr = { annualIncome: '年收入-单位:万元', students: '学员数-单位:人' };
      let k = 0;
      const value = [];
      for (var i in typeArr) {
        value[k] = { ...values[k] };
        value[k].name = typeArr[i];
        value[k].data = arr.map((item) => item[i]);
        console.log(i, k);
        k++;
      }

      const option = {
        title: { // 标题组件
          text: '',
          textStyle: {
            color: '#aaaaaa',
            fontWeight: 500,
            fontSize: 14,
            lineHeight: 45,
          },
        },
        legend: { // 图例组件
          name: '资产变化',
          type: 'plain', // 普通图例
          right: 0, // 图例组件离容器右侧的距离。
          itemWidth: 12, // 图例标记的图形宽度。
          itemHeight: 12, // 图例标记的图形高度。
          icon: 'rect', // 图例项的 icon，为 方形
          // data: ["销量"], // 图例的数据数组
          textStyle: {
            color: '#aaaaaa',
            fontWeight: 500,
            fontSize: 12,
            lineHeight: 45,
          },
        },
        xAxis: {
          type: 'category', // x轴设置为类目轴
          axisLine: {
            lineStyle: {
              color: '#aaaaaa', // 文字样式
            },
          },
          axisTick: { // 坐标轴刻度
            show: false,
          },
          axisLabel: {
            margin: 30, // 刻度标签与轴线之间的距离
          },
          data: date,
        },
        yAxis: [
          {
            type: 'value', // y轴设置为数值轴
            axisLine: {
              lineStyle: {
                color: '#aaaaaa', // 文字样式
              },
            },
            axisLabel: {
              margin: 5, // 刻度标签与轴线之间的距离
            },
            splitLine: {
              show: true, // 坐标系分隔线。
              lineStyle: {
                color: 'rgba(255,255,255,0.2)',
              },
            },
          },
        ],
        series: value,
      };
      companies.setOption(option);
    },
    drawChart() {
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      const studentPie1 = this.$echarts.init(document.getElementById('studentPie1'));
      const option = {
        title: {
          // text: 'Referer of a Website',
          // subtext: 'Fake Data',
          left: 'center'
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          textStyle: { //图例文字的样式
            color: '#fff',
          },
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['45%', '60%'],
            data: [
              { value: 1048, name: 'Search Engine' },
              { value: 735, name: 'Direct' },
              { value: 580, name: 'Email' },
              { value: 484, name: 'Union Ads' },
              { value: 300, name: 'Video Ads' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      studentPie1.setOption(option)
    },
    drawSwiper() {

      // var mySwiper = new Swiper('.swiper', {
      //   direction:'vertical',
      //   slidesPerView : 5,
      //   autoplay: true,
      // })

    },
    // slidesPerView
  },
};
</script>
<style scoped lang="scss">
@import '../static/css/common.css';
@import '..//static/css/map.css';
.wrapper-item {
  -moz-box-shadow: -1px -1px 9px #333333;
  -webkit-box-shadow: -1px -1px 9px #333333;
  box-shadow: -1px -1px 9px #333333;
}

.true-data {
  z-index: 5;
  position: absolute;
  left: 510px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.student-data {
  .data-item {
    display: flex;
    flex-direction: row;
    padding: 10px;
  }

  .item {
    flex: 1;
    text-align: center;
  }
}

.true-data-number {
  color: #66BBF9;
  font-size: 30px;
  line-height: 1;
}

.bigtitle {
  font-size: 26px;
  color: #ffffff;
  font-weight: 800;
  letter-spacing: 10px;
  text-align: center;
}

.map-hover-box{position:relative;margin-top:-160px;padding:12px 16px;min-width:200px;border-radius:20px 0 20px 0;background:rgba(11,21,50,.8);box-shadow:0 0 12px 1px #ec7d0f;word-wrap:break-word;}
.map-hover-box h3{font-size:18px;}
.map-hover-box p{padding-top:12px;font-size:16px;}
</style>
<style lang="scss">
#map{
  canvas{
    //background: rgb(0 0 0 / 50%) !important;
  }
  .amap-logo{
    display: none !important;
  }
}
.container {

  margin: 0 auto;

  width: 300px;

  height: 300px;

  position: relative;

  display: flex;

  justify-content: center;

  align-items: center;

  background: #d8d8d8;

  border: 4px solid rgba(255, 0, 0, 0.9);
  //border: 4px solid #000;

  border-radius: 10%;
  z-index: 9999;

}

.circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f77173;
}

.circle-cum {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f77173;
}

.circle-com {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #c96054;
}

.circle:nth-child(1) {
  animation: anim 3s linear infinite;

}

.circle:nth-child(2) {

  animation: anim 3s linear 0.8s infinite;

}

.circle:nth-child(3) {

  animation: anim 3s linear 1.6s infinite;

}

.circle-cum:nth-child(1) {

  border: 1px solid #4083db;
  animation: anim 3s linear infinite;

}

.circle-cum:nth-child(2) {
  border: 1px solid #4083db;
  ;
  animation: anim 3s linear 0.8s infinite;

}

.circle-cum:nth-child(3) {
  border: 1px solid #4083db;
  animation: anim 3s linear 1.6s infinite;

}

.circle-com:nth-child(1) {

  animation: anim2 3s linear infinite;

}

.circle-com:nth-child(2) {

  animation: anim2 3s linear 0.8s infinite;

}

.circle-com:nth-child(3) {

  animation: anim2 3s linear 1.6s infinite;

}

@keyframes anim {

  from {
    opacity: 1;
    background: none;
    border: 1px solid #4083db;
    background: none;
    transform: scale(0);
  }

  to {
    opacity: 0;
    border: 1px solid #4083db;
    background: none;
    transform: scale(4);
  }

}

@keyframes anim2 {

  from {
    opacity: 1;
    background: none;
    border: 1px solid #c96054;
    background: none;
    transform: scale(0);
  }

  to {
    opacity: 0;
    border: 1px solid #c96054;
    background: none;
    transform: scale(4);
  }

}

.map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  >div:last-child {
    color: rgba(255, 255, 255, 1) !important;
    font-size: 16px !important;

    .tit {
      font-size: 20px !important;
      margin-bottom: 10px;

      &+div {
        color: rgba(200, 200, 200, 1) !important;
      }
    }
  }

  &.ong>div:last-child {
    background: rgba(134, 66, 50, .6) !important;
    border-color: rgba(204, 136, 120, .8) !important;
  }
}

.mapico22 {
  position: relative;

  .info {
    position: absolute;
    bottom: 30px;
    left: 30px;
    border-radius: 3px;
    //padding: 10px 20px 15px;
    padding: 10px;
    display: none;
    color: #fff;
    width: 240px;
    height: 80px;
    justify-content: space-evenly;
    align-items: center;
    .icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;

      p {
        height: 2px;
        width: 10px;
        top: 12px;
        position: relative;
        float: right;
        span{
        display: inline-block;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        position: absolute;
        left: -6px;
        top: -3px;
      }
      }
    }

    .name {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.8);
    }

    .num {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.498039215686275);
    }
  }

  .info-com {
    background: rgba(154, 73, 68, .6);
    border: 1px solid #3a252c;

    .icon {
      border: 2px solid #e16556;
      p {
        background: #e16556;
        span{
          background: #e16556;
        }
      }
    }
  }

  .info-cum {
    .icon {
      border: 2px solid #3dafff;
      p {
        background: #3dafff;
        span{
          background: #3dafff;
        }
      }
    }
    background: rgba(61, 175, 255, .6);
    border: 1px solid #3dafff;
  }

  &:hover .info {
    display: block;
    display: flex;
  }
}
</style>