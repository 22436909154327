<template>
  <!-- <router-view class="g-body"></router-view> -->
  <div class="map" id="map" :class="onMapType == 'common' ? '' : 'ong'"></div>
  <a-modal v-if="isClick" class="modal-box" v-model:visible="isClick" title="提示" @ok="confirm(1)" @cancel="confirm(0)">
    <div v-text="cityObj.title + '经纬度是[' + cityObj.position.lng + ',' + cityObj.position.lat + ']'"></div>
  </a-modal>
</template>
<script>
import { loadAMap } from './utils/map.js';
import 'echarts-extension-amap';
// import china from './allchina.json';
// import china from './6wanquanguoyuecaidefenbu.json';
import china from './530000.topo.json';
export default {
  name: 'Maps',
  data() {
    return {
      mapObj: null, // 地图实例对象
      isClick: false, // 弹出提示框
      cityObj: null, // 城市信息
      myChart: null,
    };
  },
  props: ['area', 'areacompanys'],
  mounted() {
    // this.getGEOMap();
    this.getMap();
  },
  computed: {
    onMapType() {
      return this.$store.state.onMapType;
    },
  },
  watch: {
    onMapType() {
      this.darwMap();
    },
  },
  methods: {
    getMap() {
      // 接入高德地图
      loadAMap('56c2dd444f5e46a14b827f06a58d17b2').then((res) => {
        const chartDom = document.getElementById('map');
        const myChart = this.$echarts.init(chartDom);
        let option;
        // eslint-disable-next-line prefer-const
        option = {
          geo: {
            map: 'china',
            label: {
              emphasis: {
                show: false,
              },
              normal: {
                show: true,
                fontSize: '12',
                color: 'rgba(130, 126, 126,0.7)',
              },
            },
            roam: true,
            zoom: 11,
            itemStyle: {
              normal: {
                areaColor: 'rgba(37, 156, 224, 0.1)',
                borderColor: 'rgba(0, 16, 84, .6)',
                borderWidth: 1,
              },
              // 高亮区域
              emphasis: {
                areaColor: 'rgba(37, 156, 224, 0.15)',
              },
            },
          },
          amap: {
            zoom: 4.5, // 初始缩放大小
            // zooms: [4.5, 30], // 缩放范围
            resizeEnable: true, // 调整任意窗口的大小，自适应窗口
            center: [104.114129, 37.550339], // 初始化地图时显示的中心点坐标
            // rotation: 4.509173845626157, // 地图旋转角度
            // viewMode: '3D', //开启3D视图,默认为关闭
            // pitch: 60, // 倾斜幅度
            // 、 mapStyle: "amap://styles/71233cea099bd42a1f9463a82cab01b3"
            mapStyle: 'amap://styles/blue', // 自定义地图主题 https://geohub.amap.com/mapstyle/index
            isHotspot: true, // 是否开启地图热点和标注的 hover 效果
            showLabel: true, // 是否展示地图文字和 POI 信息。
          },
        };
        myChart.setOption(option);
        // 获取amap实例
        this.mapObj = myChart.getModel().getComponent('amap').getAMap();
        const cityArr = this.area.map((item) => {
          const por = item.coordinates.split(',');
          return {
            name: `${item.name}`,
            lat: por[1],
            lng: por[0],
            num: item.customers,
          };
        });
        const comArr = this.areacompanys.map((item) => {
          const por = item.coordinates.split(',');
          return {
            name: `${item.companyName}`,
            lat: por[1],
            lng: por[0],
            num: item.customers,
          };
        });

        for (const i in cityArr) {
          // 点标记
          // console.log(this.area[i])
          const marker = new AMap.Marker({
            // eslint-disable-next-line no-undef
            position: new AMap.LngLat(cityArr[i].lng, cityArr[i].lat),
            // icon: '/static/mapico.png',
            anchor: 'top-center',
            title: `${cityArr[i].name}`,
            zooms: [4.5, 30],
            map: this.mapObj,
            cursor: 'pointer',
            clickable: true,
            content: `<div class="mapico22 "> <div class="circle-cum"></div>
    <div class='circle-cum'></div>

    <div class='circle-cum'></div>

   <div class='circle-cum'></div><div class="info info-cum"><div class="icon"><p><span></span></p></div><div style="margin-left:18px"><div class="name">${cityArr[i].name}</div><div class="num">该地客户：${cityArr[i].num}</div></div></div></div>`,
          });
          // console.log(marker)
          // 给marker添加点击事件
          // marker.on('click', (e) => {
          //   this.isClick = true; // 显示弹窗信息
          //   this.cityObj = e.target.w; // 得到标记点的信息
          // });
          this.mapObj.add(marker); // marker置入map中
        }
        for (const j in comArr) {
          const marker = new AMap.Marker({
            position: new AMap.LngLat(comArr[j].lng, comArr[j].lat),
            anchor: 'top-center',
            title: comArr[j].name,
            zooms: [4.5, 30],
            map: this.mapObj,
            cursor: 'pointer',
            clickable: true,
            content: `<div class="mapico22 "> <div class="circle-com"></div> <div class='circle-com'></div><div class='circle-com'></div><div class='circle-com'></div><div class="info info-com "><div class="icon"><p><span></span></p></div><div style="margin-left:18px"><div class="name">${comArr[j].name}</div><div class="num">客户数：${comArr[j].num}</div></div></div></div>`,
          });
          // console.log(marker)
          // 给marker添加点击事件
          marker.on('click', (e) => {
            // this.isClick = true; // 显示弹窗信息
            // this.cityObj = e.target.w; // 得到标记点的信息
          });
          this.mapObj.add(marker); // marker置入map中
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    getGEOMap() {
      this.$echarts.registerMap('china', china);
      const chartDom = document.getElementById('map');
      this.myChart = this.$echarts.init(chartDom);
      this.darwMap();
    },
    darwMap() {
      return false;
      this.myChart.clear();
      const geoCoordMap = {
        海门: [121.15, 31.89],
        鄂尔多斯: [109.781327, 39.608266],
        招远: [120.38, 37.35],
        舟山: [122.207216, 29.985295],
        齐齐哈尔: [123.97, 47.33],
        盐城: [120.13, 33.38],
        赤峰: [118.87, 42.28],
        青岛: [120.33, 36.07],
        乳山: [121.52, 36.89],
        金昌: [102.188043, 38.520089],
        泉州: [118.58, 24.93],
        莱西: [120.53, 36.86],
        日照: [119.46, 35.42],
        胶南: [119.97, 35.88],
        南通: [121.05, 32.08],
        拉萨: [91.11, 29.97],
        云浮: [112.02, 22.93],
        梅州: [116.1, 24.55],
        文登: [122.05, 37.2],
        上海: [121.48, 31.22],
        攀枝花: [101.718637, 26.582347],
        威海: [122.1, 37.5],
        承德: [117.93, 40.97],
        厦门: [118.1, 24.46],
        汕尾: [115.375279, 22.786211],
        潮州: [116.63, 23.68],
        丹东: [124.37, 40.13],
        太仓: [121.1, 31.45],
        曲靖: [103.79, 25.51],
        烟台: [121.39, 37.52],
        福州: [119.3, 26.08],
        瓦房店: [121.979603, 39.627114],
        即墨: [120.45, 36.38],
        抚顺: [123.97, 41.97],
        玉溪: [102.52, 24.35],
        张家口: [114.87, 40.82],
        阳泉: [113.57, 37.85],
        莱州: [119.942327, 37.177017],
        湖州: [120.1, 30.86],
        汕头: [116.69, 23.39],
        昆山: [120.95, 31.39],
        宁波: [121.56, 29.86],
        湛江: [110.359377, 21.270708],
        揭阳: [116.35, 23.55],
        荣成: [122.41, 37.16],
        连云港: [119.16, 34.59],
        葫芦岛: [120.836932, 40.711052],
        常熟: [120.74, 31.64],
        东莞: [113.75, 23.04],
        河源: [114.68, 23.73],
        淮安: [119.15, 33.5],
        泰州: [119.9, 32.49],
        南宁: [108.33, 22.84],
        营口: [122.18, 40.65],
        惠州: [114.4, 23.09],
        江阴: [120.26, 31.91],
        蓬莱: [120.75, 37.8],
        韶关: [113.62, 24.84],
        嘉峪关: [98.289152, 39.77313],
        广州: [113.23, 23.16],
        延安: [109.47, 36.6],
        太原: [112.53, 37.87],
        清远: [113.01, 23.7],
        中山: [113.38, 22.52],
        昆明: [102.73, 25.04],
        寿光: [118.73, 36.86],
        盘锦: [122.070714, 41.119997],
        长治: [113.08, 36.18],
        深圳: [114.07, 22.62],
        珠海: [113.52, 22.3],
        宿迁: [118.3, 33.96],
        咸阳: [108.72, 34.36],
        铜川: [109.11, 35.09],
        平度: [119.97, 36.77],
        佛山: [113.11, 23.05],
        海口: [110.35, 20.02],
        江门: [113.06, 22.61],
        章丘: [117.53, 36.72],
        肇庆: [112.44, 23.05],
        大连: [121.62, 38.92],
        临汾: [111.5, 36.08],
        吴江: [120.63, 31.16],
        石嘴山: [106.39, 39.04],
        沈阳: [123.38, 41.8],
        苏州: [120.62, 31.32],
        茂名: [110.88, 21.68],
        嘉兴: [120.76, 30.77],
        长春: [125.35, 43.88],
        胶州: [120.03336, 36.264622],
        银川: [106.27, 38.47],
        张家港: [120.555821, 31.875428],
        三门峡: [111.19, 34.76],
        锦州: [121.15, 41.13],
        南昌: [115.89, 28.68],
        柳州: [109.4, 24.33],
        三亚: [109.511909, 18.252847],
        自贡: [104.778442, 29.33903],
        吉林: [126.57, 43.87],
        阳江: [111.95, 21.85],
        泸州: [105.39, 28.91],
        西宁: [101.74, 36.56],
        宜宾: [104.56, 29.77],
        呼和浩特: [111.65, 40.82],
        成都: [104.06, 30.67],
        大同: [113.3, 40.12],
        镇江: [119.44, 32.2],
        桂林: [110.28, 25.29],
        张家界: [110.479191, 29.117096],
        宜兴: [119.82, 31.36],
        北海: [109.12, 21.49],
        西安: [108.95, 34.27],
        金坛: [119.56, 31.74],
        东营: [118.49, 37.46],
        牡丹江: [129.58, 44.6],
        遵义: [106.9, 27.7],
        绍兴: [120.58, 30.01],
        扬州: [119.42, 32.39],
        常州: [119.95, 31.79],
        潍坊: [119.1, 36.62],
        重庆: [106.54, 29.59],
        台州: [121.420757, 28.656386],
        南京: [118.78, 32.04],
        滨州: [118.03, 37.36],
        贵阳: [106.71, 26.57],
        无锡: [120.29, 31.59],
        本溪: [123.73, 41.3],
        克拉玛依: [84.77, 45.59],
        渭南: [109.5, 34.52],
        马鞍山: [118.48, 31.56],
        宝鸡: [107.15, 34.38],
        焦作: [113.21, 35.24],
        句容: [119.16, 31.95],
        北京: [116.46, 39.92],
        徐州: [117.2, 34.26],
        衡水: [115.72, 37.72],
        包头: [110, 40.58],
        绵阳: [104.73, 31.48],
        乌鲁木齐: [87.68, 43.77],
        枣庄: [117.57, 34.86],
        杭州: [120.19, 30.26],
        淄博: [118.05, 36.78],
        鞍山: [122.85, 41.12],
        溧阳: [119.48, 31.43],
        库尔勒: [86.06, 41.68],
        安阳: [114.35, 36.1],
        开封: [114.35, 34.79],
        济南: [117, 36.65],
        德阳: [104.37, 31.13],
        温州: [120.65, 28.01],
        九江: [115.97, 29.71],
        邯郸: [114.47, 36.6],
        临安: [119.72, 30.23],
        兰州: [103.73, 36.03],
        沧州: [116.83, 38.33],
        临沂: [118.35, 35.05],
        南充: [106.110698, 30.837793],
        天津: [117.2, 39.13],
        富阳: [119.95, 30.07],
        泰安: [117.13, 36.18],
        诸暨: [120.23, 29.71],
        郑州: [113.65, 34.76],
        哈尔滨: [126.63, 45.75],
        聊城: [115.97, 36.45],
        芜湖: [118.38, 31.33],
        唐山: [118.02, 39.63],
        平顶山: [113.29, 33.75],
        邢台: [114.48, 37.05],
        德州: [116.29, 37.45],
        济宁: [116.59, 35.38],
        荆州: [112.239741, 30.335165],
        宜昌: [111.3, 30.7],
        义乌: [120.06, 29.32],
        丽水: [119.92, 28.45],
        洛阳: [112.44, 34.7],
        秦皇岛: [119.57, 39.95],
        株洲: [113.16, 27.83],
        石家庄: [114.48, 38.03],
        莱芜: [117.67, 36.19],
        常德: [111.69, 29.05],
        保定: [115.48, 38.85],
        湘潭: [112.91, 27.87],
        金华: [119.64, 29.12],
        岳阳: [113.09, 29.37],
        长沙: [113, 28.21],
        衢州: [118.88, 28.97],
        廊坊: [116.7, 39.53],
        菏泽: [115.480656, 35.23375],
        合肥: [117.27, 31.86],
        武汉: [114.31, 30.52],
        大庆: [125.03, 46.58],
      };
      const convertData = function (data) {
        const res = [];
        for (let i = 0; i < data.length; i++) {
          const geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value),
            });
          }
        }
        return res;
      };
      let max = 0;
      let min = 0;
      for (const i in this.area) {
        const item = this.area[i];
        if ((item.customers - 0) > max) {
          max = item.customers - 0;
        }
        if (min == 0 || min > (item.customers - 0)) {
          min = item.customers - 0;
        }
      }
      console.log('minmax', min, max);

      // let por = item.coordinates.split(',');
      // return {
      //   name:item.name,
      //   lat:por[1],
      //   lng:por[0],
      //   num:item.customers
      // }

      const areaArr = this[(this.onMapType == 'common' ? 'area' : 'areacompanys')].map((item) => {
        const por = item.coordinates.split(',');
        if (this.onMapType == 'common') {
          return {
            name: item.name,
            type: 'effectScatter',
            coordinateSystem: 'geo',
            data: [{
              name: `客户数：${item.customers}人`,
              value: por.concat(item.customers),
            }], // 这里的0，13是水波纹显示的前几个
            symbolSize(val, item) {
              // console.log('sizeval',val,(val[2]-min)/(max-min)*20+10)
              return (val[2] - min) / (max - min) * 20 + 10;
            },
            encode: {
              value: 2,
            },
            showEffectOn: 'render',
            rippleEffect: {
              // brushType: 'stroke'
            },
            hoverAnimation: true,
            label: {
              formatter: '',
              position: 'right',
              show: true,
              color: 'rgb(240, 250, 250)', // 文字颜色
              fontWeight: 700,
            },
            itemStyle: {
              normal: {
                color: 'rgba(0, 122, 255,.8)',
                shadowBlur: 1,
                shadowColor: '#333',
              },
            },
            // 悬浮出现的框
            tooltip: {
              formatter: `<div class="tit">${item.name}</div><div>{b}</div>`,
              textStyle: {
                color: '#ffffff',
              },
            },
            zlevel: 11,
          };
        }
        return {
          name: item.name,
          type: 'effectScatter',
          coordinateSystem: 'geo',
          data: [{
            name: `客户数：${item.customers}人`,
            value: por.concat(item.customers),
          }], // 这里的0，13是水波纹显示的前几个
          symbolSize(val, item) {
            // console.log('sizeval',val,(val[2]-min)/(max-min)*20+10)
            return (val[2] - min) / (max - min) * 35 + 10;
          },
          encode: {
            value: 2,
          },
          showEffectOn: 'render',
          rippleEffect: {
            // brushType: 'stroke'
          },
          hoverAnimation: true,
          label: {
            formatter: '',
            position: 'right',
            show: true,
            color: 'rgb(240, 250, 250)', // 文字颜色
            fontWeight: 700,
          },
          itemStyle: {
            normal: {
              color: 'rgba(255, 122, 90,.8)',
              shadowBlur: 1,
              shadowColor: '#333',
            },
          },
          // 悬浮出现的框
          tooltip: {
            formatter: `<div class="tit">${item.companyName}</div><div>{b}</div>`,
            textStyle: {
              color: '#ffffff',
            },
          },
          zlevel: 11,
        };
      });

      const option = {
        tooltip: {
          trigger: 'item',
        },
        // 地图得用这个GEo
        geo: {
          map: 'china',
          label: {
            emphasis: {
              show: false,
            },
            normal: {
              show: true,
              fontSize: '12',
              color: 'rgba(130, 126, 126,0.7)',
            },
          },
          roam: true,
          zoom: 3,
          scaleLimit: { // 所属组件的z分层，z值小的图形会被z值大的图形覆盖
            min: 2, // 最小的缩放值
            max: 11, // 最大的缩放值
          },
          itemStyle: {
            normal: {
              areaColor: 'rgba(37, 156, 224, 0.1)',
              borderColor: 'rgba(0, 16, 84, .6)',
              borderWidth: 1,
            },
            // 高亮区域
            emphasis: {
              areaColor: 'rgba(37, 156, 224, 0.15)',
            },
          },
        },
        series: areaArr,
      };
      this.myChart.setOption(option);
    },
    confirm(bool) {
      if (bool) {
        // 确定
        this.isClick = false;
      } else {
        // 取消
        this.isClick = false;
      }
    },
  },
};
</script>

<style lang="scss">
#map{
  canvas{
    //background: rgb(0 0 0 / 50%) !important;
  }
  .amap-logo{
    display: none !important;
  }
}
.container {

  margin: 0 auto;

  width: 300px;

  height: 300px;

  position: relative;

  display: flex;

  justify-content: center;

  align-items: center;

  background: #d8d8d8;

  border: 4px solid rgba(255, 0, 0, 0.9);
  //border: 4px solid #000;

  border-radius: 10%;
  z-index: 9999;

}

.circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #4083db;
}

.circle-cum {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #4083db;
}

.circle-com {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #c96054;
}

.circle:nth-child(1) {
  animation: anim 3s linear infinite;

}

.circle:nth-child(2) {

  animation: anim 3s linear 0.8s infinite;

}

.circle:nth-child(3) {

  animation: anim 3s linear 1.6s infinite;

}

.circle-cum:nth-child(1) {

  border: 1px solid #4083db;
  animation: anim 3s linear infinite;

}

.circle-cum:nth-child(2) {
  border: 1px solid #4083db;
  ;
  animation: anim 3s linear 0.8s infinite;

}

.circle-cum:nth-child(3) {
  border: 1px solid #4083db;
  animation: anim 3s linear 1.6s infinite;

}

.circle-com:nth-child(1) {

  animation: anim2 3s linear infinite;

}

.circle-com:nth-child(2) {

  animation: anim2 3s linear 0.8s infinite;

}

.circle-com:nth-child(3) {

  animation: anim2 3s linear 1.6s infinite;

}

@keyframes anim {

  from {
    opacity: 1;
    background: none;
    border: 1px solid #4083db;
    background: none;
    transform: scale(0);
  }

  to {
    opacity: 0;
    border: 1px solid #4083db;
    background: none;
    transform: scale(4);
  }

}

@keyframes anim2 {

  from {
    opacity: 1;
    background: none;
    border: 1px solid #c96054;
    background: none;
    transform: scale(0);
  }

  to {
    opacity: 0;
    border: 1px solid #c96054;
    background: none;
    transform: scale(4);
  }

}

.map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  >div:last-child {
    color: rgba(255, 255, 255, 1) !important;
    font-size: 16px !important;

    .tit {
      font-size: 20px !important;
      margin-bottom: 10px;

      &+div {
        color: rgba(200, 200, 200, 1) !important;
      }
    }
  }

  &.ong>div:last-child {
    background: rgba(134, 66, 50, .6) !important;
    border-color: rgba(204, 136, 120, .8) !important;
  }
}

.mapico22 {
  position: relative;

  .info {
    position: absolute;
    bottom: 30px;
    left: 30px;
    border-radius: 3px;
    //padding: 10px 20px 15px;
    padding: 10px;
    display: none;
    color: #fff;
    width: 240px;
    height: 80px;
    justify-content: space-evenly;
    align-items: center;
    .icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;

      p {
        height: 2px;
        width: 10px;
        top: 12px;
        position: relative;
        float: right;
        span{
        display: inline-block;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        position: absolute;
        left: -6px;
        top: -3px;
      }
      }
    }

    .name {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.8);
    }

    .num {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.498039215686275);
    }
  }

  .info-com {
    background: rgba(154, 73, 68, .6);
    border: 1px solid #3a252c;

    .icon {
      border: 2px solid #e16556;
      p {
        background: #e16556;
        span{
          background: #e16556;
        }
      }
    }
  }

  .info-cum {
    .icon {
      border: 2px solid #3dafff;
      p {
        background: #3dafff;
        span{
          background: #3dafff;
        }
      }
    }
    background: rgba(61, 175, 255, .6);
    border: 1px solid #3dafff;
  }

  &:hover .info {
    display: block;
    display: flex;
  }
}</style>
