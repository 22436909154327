<template>
   <!-- <a-config-provider :locale="locale" :getPopupContainer="getPopupContainer">
    <Maps v-if="areaList.length" :area="areaList" :areacompanys="areacList" />
    <div class="g-title">
      <span>天意集数据监控大屏</span>
      <div class="time">
        <div v-for="(i,k,idx) in time" class="span" :class="k">
          <span v-for="j in i" v-text="j"></span>
        </div>
        <div class="switch" v-if="false" @click="pagelink"><img src="@/static/switch-ico.png" alt="" class="ico" />切换</div>
      </div>

  <router-view class="g-body"></router-view>
    </div>
  </a-config-provider>  -->
  <router-view class="g-body"></router-view>
</template>
<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import Maps from '@/components/map.vue';

export default {
  data() {
    return {
      locale: zhCN,
      time: {
        year: [0, 0, 0, 0],
        month: [0, 0],
        day: [0, 0],
        hour: [0, 0],
        minute: [0, 0],
        second: [0, 0],
        week: [''],
      },
      areaList: [],
      areacList: [],
    };
  },
  components: {
    Maps,
  },
  created() {
    // this.getTime();
    // setInterval(() => {
    //   this.getTime();
    // }, 1000);
    // this.getData();
  },
  methods: {
    async getData() {
      console.log(this.$utils);
      const res = await this.$utils.api.get({
        url: '/portal/statistics/data',
      });
      this.areaList = res.areas;
      this.areacList = res.areaCompanys;
    },
    pagelink() {
      if (this.$route.name == 'Home') {
        this.$router.replace({ path: '/DataShow' });
      } else {
        this.$router.replace({ path: '/' });
      }
    },
    getPopupContainer(el, dialogContext) {
      if (dialogContext) {
        return dialogContext.getDialogWrap();
      }
      return document.body;
    },
    getTime() {
      const now = new Date();
      const nowObj = {
        year: `${now.getFullYear()}`,
        month: now.getMonth() + 1,
        day: now.getDate(),
        hour: now.getHours(),
        minute: now.getMinutes(),
        second: now.getSeconds(),
        week: ['日一二三四五六'.charAt(now.getDay())],
      };
      for (const i in nowObj) {
        if (nowObj[i] < 10) {
          nowObj[i] = `0${nowObj[i]}`;
        } else {
          nowObj[i] = `${nowObj[i]}`;
        }
        this.time[i] = nowObj[i].split('');
      }
    },

  },
};
</script>

<style lang="scss">
#app{
  position: relative;
  width:100vw;
  height:100vh;
  //background:#0c3446;
}
.g-title{
  width:100%;
  padding:10px 0;
  height:117px;
  background:url(./static/title.png) center center no-repeat;
  text-align: center;
  font-size:32px;
  line-height:50px;
  color:#03B4F5;
  position: relative;
  z-index: 3;
  .time{
    position: absolute;
    right:30px;
    bottom:20px;
    display: flex;
    align-items: center;
    font-size:20px;
    .year:after{
      content:'年';
      color:#ccc;
    }
    .month:after{
      content:'月';
      color:#ccc;
    }
    .day:after{
      content:'日　';
      color:#ccc;
    }
    .second{
      &:after{
          content:'　';
        }
      }
    .hour,.minute{
      &:after{
        content:':';
        color:#ccc;
      }
    }
    .week:before{
        content:'星期';
        color:#ccc;
    }
    .switch{
      width:90px;
      height:35px;
      line-height:32px;
      color:#2391FF;
      border: 1px solid;
      font-size:16px;
      display: inline-block;
      margin-left:20px;
      cursor: pointer;
      .ico{
        width:20px;
        height:20px;
        display: inline-block;
        vertical-align: middle;
        margin-right:10px;
        position: relative;
        top:-2px;
      }
    }
    .switch:hover{
        color:#fff;
        border: 1px solid #2391FF;
        background-color:rgb(43, 91, 152);
      }
  }
}
.g-body{

}
.amap-ec-layer{
	z-index: -10;
}
.ant-modal-mask{
	background-color: rgba(0, 0, 0, 0);
}
.modal-box{
	top:300px;
	padding-bottom: 0;
	box-shadow: 0 0 8px rgba(0, 0, 0, .8);
	.ant-modal-content{
		background:rgba(0,0,0,.3);
		z-index: 6;
	}
	.ant-modal-header{
		background:rgba(0,0,0,0);
		border-bottom-color: rgba(255,255,255,.2);
		.ant-modal-title{
			color: #fff;
		}
	}
	.ant-modal-footer{
		border-top-color: rgba(255,255,255,.2);
		.ant-btn{
			&:nth-child(1){
				color:#2391FF;
				border-color: #2391FF;
				background-color: transparent;
				&:hover{
					color:#fff;
				}
			}
			&:nth-child(2){
				background-color: #2391FF;
				&:hover{
					background-color: rgba(35, 145, 255, 0.7);
					border-color: rgba(35, 145, 255, 0.7);
				}
			}
		}
	}
	.ant-modal-close ,.ant-modal-body{
		color: #fff;
	}
}
</style>
