<template>
	<div>
		<div class="lf-box">
			<div class="top-one">
				<div class="title">活动实时交易情况</div>
				<div class="top-map">
					<div id="map-small" class="map-small"></div>
					<div class="top-li">
						<div class="top-li-box">
							<div class="txt">今日销售总额</div>
							<div class="price">854，323 <span>元</span></div>
						</div>
						<div class="top-li-box">
							<div class="txt">今日课程产品销售额</div>
							<div class="price">854，323 <span>元</span></div>
						</div>
						<div class="top-li-box">
							<div class="txt">今日课程产品订单数量</div>
							<div class="price">10000</div>
						</div>
						<div class="top-li-box">
							<div class="txt">今日视频课程销售额</div>
							<div class="price">854，323 <span>元</span></div>
						</div>
					</div>
					<div class="to-bottom">
						<div class="txt">销售额</div>
						<div class="sale-box">
							<div class="bg-box">
								<div class="bg" style="background-color: #014eb6;"></div>
								<div class="bg" style="background-color: #138fff;"></div>
								<div class="bg" style="background-color: #6bbffd;"></div>
								<div class="bg" style="background-color: #b8e7ff;"></div>
							</div>
							<div class="bg-box">
								<div class="bg1">
									<div class="fangkui" style="background-color: #014eb6;"></div>
									<div class="label">大于100万元 36%</div>
								</div>
								<div class="bg1">
									<div class="fangkui" style="background-color: #138fff;"></div>
									<div class="label">100万~10万元 24%</div>
								</div>
								<div class="bg1">
									<div class="fangkui" style="background-color: #6bbffd;"></div>
									<div class="label">10万~1万元 20%</div>
								</div>
								<div class="bg1">
									<div class="fangkui" style="background-color: #b8e7ff;"></div>
									<div class="label">小于1万元 20%</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bottom-two">
				<div class="bm-left">
					<div class="title">学员行业占比</div>
					<div class="bm-student">
						<div id="student1" class="student"></div>
						<div id="student2" class="student"></div>
						<div id="student3" class="student"></div>
					</div>
				</div>
				<div class="bm-right">
					<div class="title">到课率</div>
					<div class="course-box">
						<div id="course" class="course"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="rt-box">
			<div class="rt-top">
				<div class="first">
					<div class="title">学员人数统计</div>
					<div class="num-box1">当前学员数</div>
					<div class="num-box2">123456<span>人</span></div>
					<div class="stuNumber-box">
						<div id="stuNumber" class="stuNumber"></div>
					</div>
				</div>
				<div class="second">
					<div class="title">线索转化</div>
					<div class="speed-box">
						<div id="speed" class="speed"></div>
					</div>
				</div>
			</div>
			<div class="third">
				<div class="title">转化漏斗</div>
				<div class="funnel-box">
					<div id="funnel" class="funnel"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { loadAMap } from '../components/utils/map.js'
import "echarts-extension-amap";
import '../components/utils/china.js'

export default {
	name: 'DataMore',
	data() {
		return {
			mapObj: null, // 地图实例对象
		}
	},
	mounted() {
		this.getMap()
	},
	methods: {
		getMap() {
			var that = this
			// 接入高德地图
			loadAMap("09e72f419512ab00f230ba7bbdc64d97").then((res) => {
				// ---------------------------------中国地图
				var chartDom = document.getElementById('map-small');
				var myChart = this.$echarts.init(chartDom);
				var option = {
					tooltip: {
						show: false
					},
					geo: {
						map: "china",
						roam: false,// 一定要关闭拖拽
						zoom: 1.23,
						center: [105, 36], // 调整地图位置
						label: {
							normal: {
								show: false, //关闭省份名展示(重复的)
								fontSize: "10",
								color: "#999999"
							},
							emphasis: {
								show: false
							}
						},
						itemStyle: {
							normal: {
								areaColor: "#e9f1f7",
								borderColor: "#c4cbcf",
								borderWidth: 1, //设置外层边框
								shadowBlur: 0, // 轮廓偏移
								shadowOffsetY: 0,
								shadowOffsetX: 0,
								shadowColor: "#01012a"
							},
							emphasis: {
								areaColor: "#e9f1f7",
								shadowOffsetX: 0,
								shadowOffsetY: 0,
								shadowBlur: 0,
								borderWidth: 0,
								shadowColor: "rgba(0, 0, 0, 0.5)"
							}
						}
					},
					series: [
						{
							type: "map",
							map: "china",
							roam: false,
							zoom: 1.23,
							center: [105, 36],
							showLegendSymbol: false, // 存在legend时显示
							label: {
								normal: {
									show: true, // 显示省份名称
									color: "#999999"
								},
								emphasis: {
									show: true,
									textStyle: {
										color: "#999999"
									}
								}
							},
							itemStyle: {
								normal: {
									areaColor: "#e9f1f7",
									borderColor: "#c4cbcf",
									borderWidth: 1
								},
								emphasis: {
									areaColor: "#e9f1f7",
									shadowOffsetX: 0,
									shadowOffsetY: 0,
									shadowBlur: 0,
									borderWidth: 0,
									shadowColor: "rgba(0, 0, 0, 0)"
								}
							}
						}
					]
				};

				option && myChart.setOption(option);
				// ---------------------------------中国地图

				// ---------------------------------环形图
				var studentList = [
					{ name: '餐饮', num: 28, col: '#36a1fe', stuObj: null },
					{ name: '电商', num: 22, col: '#63d585', stuObj: null },
					{ name: '制造', num: 12, col: '#f6db5a', stuObj: null }
				]
				studentList[0].stuObj = this.$echarts.init(document.getElementById('student1')),
					studentList[1].stuObj = this.$echarts.init(document.getElementById('student2')),
					studentList[2].stuObj = this.$echarts.init(document.getElementById('student3'));
				var stuOption;
				stuOption = {
					tooltip: {
						trigger: 'item',
						formatter: ''
					},
					series: [
						{
							name: '',
							type: 'pie',
							radius: ['50%', '70%'],
							avoidLabelOverlap: false,
							label: {
								show: true,
								position: 'center'
							},
							emphasis: {
								label: {
									show: true,
									fontSize: '18',
									fontWeight: 'bold'
								},
								disabled: true // 关闭高亮放大效果
							},
							labelLine: {
								show: true
							},
							data: []
						}
					]
				};
				for (let i in studentList) {
					stuOption.tooltip.formatter = studentList[i].name + ' ' + studentList[i].num + '%'
					stuOption.series[0].data = []
					let val = [
						{
							value: studentList[i].num,
							name: studentList[i].name,
							itemStyle: { color: studentList[i].col }
						},
						{
							value: 100 - studentList[i].num,
							name: studentList[i].name,
							itemStyle: { color: '#f1f2f6' }
						}
					]
					stuOption.series[0].data = val
					studentList[i].stuObj.setOption(stuOption)
				}
				// ---------------------------------环形图

				// ---------------------------------折线图
				let course = this.$echarts.init(document.getElementById('course'));
				let courseOption = {
					title: { // 标题组件
						text: "",
						textStyle: {
							color: '#aaaaaa',
							fontWeight: 500,
							fontSize: 14,
							lineHeight: 45
						}
					},
					legend: { // 图例组件
						name: '',
						type: 'plain', // 普通图例
						right: 0, // 图例组件离容器右侧的距离。
						itemWidth: 12, // 图例标记的图形宽度。
						itemHeight: 12, // 图例标记的图形高度。
						icon: 'rect', // 图例项的 icon，为 方形
						// data: ["销量"], // 图例的数据数组
						textStyle: {
							color: '#aaaaaa',
							fontWeight: 500,
							fontSize: 12,
							lineHeight: 45
						}
					},
					xAxis: {
						type: 'category', // x轴设置为类目轴
						axisLine: {
							lineStyle: {
								color: '#aaaaaa' // 文字样式
							}
						},
						axisTick: { // 坐标轴刻度
							show: false,
						},
						axisLabel: {
							margin: 20 // 刻度标签与轴线之间的距离
						},
						data: ["1月", "2月", "3月", "4月", "5月"],
					},
					yAxis: [
						{
							type: 'value',// y轴设置为数值轴
							axisLine: {
								lineStyle: {
									color: '#aaaaaa'// 文字样式
								}
							},
							axisLabel: {
								margin: 15 // 刻度标签与轴线之间的距离
							},
							splitLine: {
								show: true,// 坐标系分隔线。
								lineStyle: {
									color: 'rgba(0,0,0,0.2)'
								}
							}
						}
					],
					series: [
						{
							name: "", // 系列名称
							type: 'line', // 类型：折线图
							smooth: true, // 是否平滑曲线显示
							symbol: 'none', // 线两端的标记类型
							lineStyle: { // 线条样式
								width: 3
							},
							itemStyle: { // 图例的图形样式、线条的样式
								color: {
									type: 'linear', // 渐变
									x: 1,
									y: 0,
									x2: 0,
									y2: 0,
									colorStops: [{
										offset: 0, color: '#00aaff' // 0% 处的颜色
									}, {
										offset: 1, color: '#00aaff' // 100% 处的颜色
									}],
									global: false // 缺省为 false
								}
							},
							data: [10, 20, 30, 40, 80, 60, 30],
						},
					]
				};
				course.setOption(courseOption);
				// ---------------------------------折线图

				// ---------------------------------面积图
				var stuNumberObj = this.$echarts.init(document.getElementById('stuNumber'));
				var stuNumberOption;
				stuNumberOption = {
					xAxis: {
						type: 'category', // x轴设置为类目轴
						boundaryGap: false,
						axisLine: {
							lineStyle: {
								color: '#aaaaaa' // 文字样式
							}
						},
						axisTick: { // 坐标轴刻度
							show: false,
						},
						axisLabel: {
							margin: 20 // 刻度标签与轴线之间的距离
						},
						data: ["2018", "2020", "2022"],
					},
					yAxis: [
						{
							type: 'value',// y轴设置为数值轴
							axisLine: {
								lineStyle: {
									color: '#aaaaaa'// 文字样式
								}
							},
							axisLabel: {
								margin: 15 // 刻度标签与轴线之间的距离
							},
							splitLine: {
								show: true,// 坐标系分隔线。
								lineStyle: {
									color: 'rgba(0,0,0,0.2)'
								}
							}
						}
					],
					series: [
						{
							type: 'line',
							smooth: true, // 是否平滑曲线显示
							symbol: 'none', // 线两端的标记类型
							lineStyle: { // 线条样式
								width: 3
							},
							areaStyle: {
								color: '#d0e9ff'
							},
							itemStyle: { // 图例的图形样式、线条的样式
								color: {
									type: 'linear', // 渐变
									x: 1,
									y: 0,
									x2: 0,
									y2: 0,
									colorStops: [{
										offset: 0, color: '#00aaff' // 0% 处的颜色
									}, {
										offset: 1, color: '#00aaff' // 100% 处的颜色
									}],
									global: false // 缺省为 false
								}
							},
							data: [10, 20, 50],
						}
					]
				};

				stuNumberObj.setOption(stuNumberOption);
				// ---------------------------------面积图

				// ---------------------------------速度仪表盘
				var speedObj = this.$echarts.init(document.getElementById('speed'));
				var speedOption;
				speedOption = {
					series: [
						{
							type: 'gauge',
							progress: {
								show: true,
								width: 10,
								itemStyle: {
									color: '#00aaff'
								}
							},
							axisLine: {
								lineStyle: {
									width: 10
								}
							},
							axisTick: {
								show: false
							},
							splitLine: {
								length: 10,
								lineStyle: {
									width: 2,
									color: '#00aaff'
								}
							},
							axisLabel: {
								distance: 15,
								color: '#333333',
								fontSize: 12
							},
							anchor: {
								show: true,
								showAbove: true,
								size: 15,
								itemStyle: {
									borderWidth: 5,
									color: '#ffffff',
									borderColor: '#00aaff'
								}
							},
							pointer: {
								width: 5,
								itemStyle: {
									color: '#00aaff',
								}
							},
							title: {
								show: false
							},
							detail: {
								valueAnimation: true,
								fontSize: 20,
								offsetCenter: [0, '70%']
							},
							data: [
								{
									value: 20
								}
							]
						}
					]
				};
				speedObj.setOption(speedOption);
				// ---------------------------------速度仪表盘

				// ---------------------------------漏斗图
				var funnelObj = this.$echarts.init(document.getElementById('funnel'));
				var funnelOption;
				funnelOption = {
					title: {
						text: ''
					},
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b} : {c}%'
					},
					legend: {
						data: ['获取', '激活', '留存', '下单', '推荐']
					},
					series: [
						{
							name: '',
							type: 'funnel',
							left: '10%',
							top: 60,
							bottom: 60,
							width: '80%',
							min: 0,
							max: 100,
							minSize: '0%',
							maxSize: '100%',
							sort: 'descending',
							gap: 2,
							label: {
								show: true,
								position: 'inside'
							},
							labelLine: {
								length: 10,
								lineStyle: {
									width: 1,
									type: 'solid'
								}
							},
							itemStyle: {
								borderColor: '#fff',
								borderWidth: 1
							},
							emphasis: {
								label: {
									fontSize: 20
								}
							},
							data: [
								{ value: 60, name: 60 },
								{ value: 58, name: 58 },
								{ value: 20, name: 20 },
								{ value: 80, name: 80 },
								{ value: 100, name: 100 }
							]
						}
					]
				};

				funnelObj.setOption(funnelOption);
				// ---------------------------------漏斗图
			}).catch((err) => {
				console.log(err);
			});
		}
	},
};
</script>

<style lang="scss" scoped>
.lf-box {
	position: absolute;
	top: 120px;
	left: 50px;
	bottom: 50px;
	width: 1250px;
	z-index: 5;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.top-one {
		width: 100%;
		height: 70%;
		background-color: #ffffff;
		border-radius: 3px;
		overflow: hidden;

		.top-map {
			position: relative;
			height: 100%;

			.map-small {
				position: absolute;
				top: 45%;
				left: 50%;
				transform: translate(-45%, -50%);
				width: 550px;
				height: 550px;
			}

			.top-li {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				z-index: 10;
				height: 80px;
				display: flex;
				justify-content: space-around;
				align-items: center;

				.top-li-box {
					width: 20%;

					.txt {
						font-size: 14px;
						color: #999999;
					}

					.price {
						font-size: 18px;
						color: #333333;
						font-weight: bold;

						span {
							font-size: 12px;
						}
					}
				}

			}

			.to-bottom {
				position: absolute;
				bottom: 55px;
				z-index: 10;
				display: flex;
				align-items: flex-start;
				width: 100%;
				padding: 10px 30px;

				.txt {
					width: 80px;
					font-size: 14px;
					color: #999999;
				}

				.sale-box {
					width: 100%;

					.bg-box {
						display: flex;

						.bg {
							flex: 1;
							height: 10px;
						}

						.bg1 {
							flex: 1;
							font-size: 12px;
							color: #999999;
							margin-top: 5px;
						}
					}
				}
			}
		}
	}

	.bottom-two {
		width: 100%;
		height: 27%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.bm-left {
			width: 49%;
			height: 100%;
			background-color: #ffffff;
			border-radius: 3px;
			overflow: hidden;

			.bm-student {
				display: flex;
				justify-content: space-around;
				align-items: center;
				height: 80%;
				padding: 0 50px;

				.student {
					width: 150px;
					height: 150px;
				}
			}
		}

		.bm-right {
			width: 49%;
			height: 100%;
			background-color: #ffffff;
			border-radius: 3px;
			overflow: hidden;

			.course-box {
				display: flex;
				justify-content: center;
			}

			.course {
				width: 300px;
				height: 200px;
			}
		}
	}
}

.rt-box {
	position: absolute;
	top: 120px;
	right: 50px;
	bottom: 50px;
	width: 500px;
	z-index: 5;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.rt-top {
		width: 100%;
		height: 70%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.first {
			position: relative;
			width: 100%;
			height: 47.9%;
			background-color: #ffffff;
			border-radius: 3px;
			overflow: hidden;

			.num-box1 {
				font-size: 14px;
				color: #999999;
				padding: 0 20px;
				margin-top: 10px;
			}

			.num-box2 {
				font-size: 18px;
				font-weight: bold;
				color: #333333;
				padding: 0 20px;
				margin-bottom: 10px;

				span {
					font-size: 12px;
				}
			}

			.stuNumber-box {
				position: absolute;
				top: 80px;
				left: 50%;
				transform: translateX(-50%);

				.stuNumber {
					width: 300px;
					height: 230px;
				}
			}
		}

		.second {
			width: 100%;
			height: 47.9%;
			background-color: #ffffff;
			border-radius: 3px;
			overflow: hidden;
			position: relative;

			.speed-box {
				position: absolute;
				top: 50px;
				left: 50%;
				transform: translateX(-50%);

				.speed {
					width: 250px;
					height: 250px;
				}
			}
		}
	}

	.third {
		position: relative;
		width: 100%;
		height: 27%;
		background-color: #ffffff;
		border-radius: 3px;
		overflow: hidden;

		.funnel-box {
			position: absolute;
			top: 15px;
			left: 50%;
			transform: translateX(-50%);

			.funnel {
				width: 450px;
				height: 230px;
			}
		}
	}
}

.title {
	width: 100%;
	height: 50px;
	line-height: 50px;
	padding-left: 20px;
	font-size: 18px;
	color: #333333;
	border-bottom: 1px solid #eaebec;
	font-weight: bold;
}</style>