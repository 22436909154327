import { createApp,Vue } from 'vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import 'dayjs/locale/zh-cn';
import * as Icons from '@ant-design/icons-vue';
import * as echarts from 'echarts';
import App from './App.vue';
import router from './router';
import store from './store';
import utils from './components/common.js';
const app = createApp(App);
app.use(store).use(Antd);
app.config.globalProperties.$utils = utils;
app.config.globalProperties.$echarts = echarts;

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

// 全局注册图标
const icons = Icons;
for (const i in icons) {
  app.component(i, icons[i]);
}

app.use(router).mount('#app');
