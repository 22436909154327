import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import Home from '../views/Home.vue';
import DataShow from '../views/datashow.vue';
import DataMore from '../views/datamore.vue';
import Demo from '../views/demo.vue';
import Newv from '../views/newv.vue';


const routes = [
  {
    path: '/',
    name: 'Home',
    // component: Demo,
    component: Home,
    meta: {
      title: '天意集数据监控大屏',
    },
    // component: DataShow,
  },
  {
    path: '/Demo',
    name: 'Demo',
    component: Demo,
    meta: {
      title: '天意集数据监控大屏',
    },
    // component: DataShow,
  },
   {
    path: '/DataShow',
    name: 'DataShow',
    meta: {
      title: '天意集数据监控大屏',
    },
    component: DataShow,
  },
  {
    path: '/newv',
    name: 'newv',
    meta: {
      title: '天意集数据监控大屏',
    },
    component: Newv,
  },
   {
    path: '/DataMore',
    name: 'DataMore',
    meta: {
      title: '天意集数据监控大屏',
    },
    component: DataMore,
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  base: '/',
  routes,
});

export default router;
