import { createStore } from 'vuex';
import $utils from '@/components/utils/tools';

export default createStore({
	state: {
		user: null,// 用户信息
		configData: null,// 配置参数
		proId: 0,// 项目ID
		proName:'',// 项目名称
		mainNavIndex: 0,// 主导航索引
		subNavIndex: 0,// 次导航索引
		selectedMemu:[],// 选中的菜单信息
		params: [],
		hideSider: 0,
		history:null,
		onMapType:'common',// 'company'
	},
	mutations: {
		setStore (state, obj) {
			// obj.name state下的键名、obj.data 待设置的数据、obj.storage 数据是否缓存
			state[obj.name] = obj.data || null;
			if(obj.storage) {
				if(obj.data){
					$utils.storeSet(obj.name, obj.data)
				} else {
					$utils.storeClear(obj.name)
				}
			}
		},
	},
	actions: {
	},
	modules: {
	},
});
